
.dollar-repayment {
    padding-left: 20px;
    text-align: left;
}

.currencyForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #0288D1;
    padding: 0 0 0 10px;
    margin: 10px 0;
    height: 48px;
    border-radius: 5px;
    width: 25%;
    font-size: 20px;
}

.loanIdInput {
    width: 50%;
    height: 3rem;
    border-radius: 5px;
    border: 1px solid #0288D1;
    margin: 10px 0;
    padding: 0 0 0 15px;
    font-size: 20px;
}

.modalInput {
    width: 100%;
    height: 3rem;
    border-radius: 5px;
    border: 1px solid #0288D1;
    margin: 10px 0;
    padding: 0 0 0 15px;
    font-size: 20px;
}

.singleUserDropdown {
    color: #0288D1;
}

input:focus, textarea:focus, select:focus {
    outline: none;
}
