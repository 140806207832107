div#rates {
    text-align: center;
}

div.ratesForm {
    text-align: center;
}

input.ratesInput {
    width: 100%;
    height: 70px;
    border-radius: 3px;
    border: 1px solid blue;
    padding: 10px;
    font-size: 1.5rem;
}

Button.submitRatesButton {
    width: 200px;
    height: 60px;
    border-radius: .27rem;
    margin: 10px;
    border: 1px solid #6e97f0;
    font-size: 1.5rem;
}

.noBorderOutline {
    border: 0;
    outline: 0;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
}

input:focus, textarea:focus, select:focus {
    outline: none;
}

.marginRightWide {
    margin-right: 35px;
}

.marginLeftWide {
    margin-left: 35px;
}

@media only screen and (max-width: 839px) {
    .marginRightWide {
        margin-right: 0;
    }
    
    .marginLeftWide {
        margin-left: 0;
    }
}
