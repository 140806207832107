p {
    margin-top: unset;
    margin-bottom: unset;
  }
  
  .link {
    text-decoration: none;
    color: #000;
  }
  
  .csvButton {
    background-color: #FFF;
    color: #0288D1;
    border: 1px solid #0288D1;
    font-size: 13px;
    width: max-content;
    height: auto;
  }

  .liquidationDetails {
    display: flex;
    flex-direction: column;
    font-size: 17px;
  }

  .liquidationDetailsTitle {
    font-size: 20px;
  }