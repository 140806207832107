body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pro-sidebar-content {
  overflow: auto;
}

.notification {
    background:             #FFFFFF;
    color:                  #353535;
    box-shadow:             0px 0.5px 2px 0 #000;
    padding:                15px;
    line-height:            130%;
    letter-spacing:         -0.02em;
    display:                flex;
    align-items:            center;
    padding-right:          35px;
    border-radius:          10px;
}

.notification-img {
    width:                  21px;
    height:                 21px;
    margin-left:            10px;
    margin-right:           20px;
}

.notification-title {
    font-weight:            bold;
    font-size:              18px;
    line-height:            130%;
    letter-spacing:         -0.02em;
}

.notification-content {
    font-size:              14px;
    line-height:            130%;
    letter-spacing:         -0.02em;
    color:                  #353535;
}

#loading {
    height:                 50vh;
    width:                  100%;
    display:                flex;
    justify-content:        center;
    align-items:            center;
    animation-name: spin;
    animation-duration: 300ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

#spinner {
    width: 30px;
    height: auto;
}
