.colorKey {
    padding-left: 2rem;
}

h6 {
    margin: .5rem .5rem 1rem 0;
}

.c1 {
    color:#f44336;
}

.c2 {
    color:#fccb00;
}

.c3 {
    color: #006b76;
}

.c4 {
    color:#008b02;
}

.c5 {
    color: #1273de;
}

.c6 {
    color:#9900ef;
}

.c7 {
    color:#f5a623;
}

@media screen and (min-width: 768px) {
    .colorKey {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
    h6 {
        flex-basis: 40%;
    }
}

@media screen and (min-width: 900px) {
    h6 {
        flex-basis: 30%;
    }
}